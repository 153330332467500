<template>
  <div class="charts-item">
    <charts :option="option" :id="item"></charts>
  </div>
</template>
<script>
import charts from "@/components/charts/charts.vue";
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    charts,
  },
  watch: {
    "$attrs.chartsUUID"() {
      this.setOption();
    },
  },
  created() {
    this.item = `${this.id}-cross`;
    this.setOption();
  },
  data() {
    return {
      item: "",
      option: {},
    };
  },
  methods: {
    setOption() {
      let { xData, yData, legged, dbDate } = this.$attrs;
      let list = [...legged];
      if (dbDate && dbDate.length) {
        list.push(`${dbDate[0]} ~ ${dbDate[1]}`);
      }
      this.option = {
        tilte: {},
        // color: ["#2FC25B", "#336FFE"],
        tooltip: {
          trigger: "axis",
        },
        color: [
          "rgb(203,155,255)",
          "rgb(149,162,255)",
          "rgb(58,186,255)",
          "rgb(119,168,249)",
          "rgb(235,161,159)",
        ],
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xData,
          axisTick: {
            lineStyle: {
              color: "#D9D9D9",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#D9D9D9",
            },
          },
          axisLabel: {
            color: "#666666",
            fontSize: 12,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#E9E9E9",
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        grid: {
          show: "true",
          borderWidth: "0",
          left: "5%",
          right: "6%",
          bottom: "5%",
          containLabel: true,
        },
        series: yData.map((item, index) => {
          let obj = {
            name: list[index],
            showSymbol: false,
            data: item.value,
            type: "line",
            showSymbol: false,
            //区域配置
            smooth: true,
            symbol: "circle",
            symbolSize: 0,
            itemStyle: {
              normal: {
                areaStyle: {
                  type: "default",
                  opacity: 0.1,
                },
              },
            },
          };
          return obj;
        }),
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.charts-item {
  height: 100%;
}
</style>